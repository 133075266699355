import { Box } from "@chakra-ui/react";
import { cloneElement } from "react";
import { WhatsappIcon } from "./Icons";

function WhatsButton() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "4px",
        p: "2",
        bg: "white",
        borderRadius: "200px",
        cursor: "pointer",
      }}
      shadow="md"
    >
      {cloneElement(<WhatsappIcon />, {
        color: "brand.500",
        boxSize: "20",
        width: "54px",
        height: "54px",
        borderRadius: "50",
      })}
    </Box>
  );
}

export default WhatsButton;
