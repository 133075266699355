import React from "react";
import { Box, Image, HStack, Text, Heading, Link } from "@chakra-ui/react";
import DonationButton from "./DonationButton";
import { useLocation } from "react-router-dom";
import WhatsButton from "./WhatsButton";

function DonationWhatsGroup() {
  const location = useLocation();
  const isPathDonation = location.pathname === "/donation";
  return (
    <HStack
      pos="fixed"
      zIndex="20"
      bottom="20"
      spacing="3"
      right={{ base: "10", "2xl": "10%" }}
    >
      <WhatsButton />
      {isPathDonation ? null : <DonationButton />}
    </HStack>
  );
}

export default DonationWhatsGroup;
